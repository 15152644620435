import React from 'react';
import Footer from '../components/LandingPage/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="w-full min-h-screen overflow-y-auto">
      <div className="max-w-3xl mx-auto py-12 px-8">
        <h1 className="text-3xl font-bold text-charcoal-500 mb-8">
          Privacy Policy
        </h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            1. Information Collection
          </h2>
          <p className="text-charcoal-500">
            We collect information you provide directly to us when you:
          </p>
          <ul className="list-disc pl-5 mt-2 text-charcoal-500">
            <li>Create an account</li>
            <li>Use our service</li>
            <li>Communicate with us</li>
          </ul>
          <p className="mt-2 text-charcoal-500">
            This may include your name, email address, and any other information
            you choose to provide.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            2. Use of Information
          </h2>
          <p className="text-charcoal-500">
            We use the information we collect to:
          </p>
          <ul className="list-disc pl-5 mt-2 text-charcoal-500">
            <li>Provide, maintain, and improve our services</li>
            <li>Communicate with you about our services</li>
            <li>Respond to your comments, questions, and requests</li>
            <li>
              Monitor and analyze trends, usage, and activities in connection
              with our services
            </li>
            <li>
              Detect, investigate, and prevent fraudulent transactions and other
              illegal activities
            </li>
            <li>Comply with legal obligations</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            3. Information Sharing and Disclosure
          </h2>
          <p className="text-charcoal-500">
            We may share personal information as follows:
          </p>
          <ul className="list-disc pl-5 mt-2 text-charcoal-500">
            <li>With your consent</li>
            <li>
              With vendors, consultants, and other service providers who need
              access to such information to carry out work on our behalf
            </li>
            <li>
              In response to a request for information if we believe disclosure
              is in accordance with any applicable law, regulation, or legal
              process
            </li>
            <li>
              If we believe your actions are inconsistent with the spirit or
              language of our user agreements or policies, or to protect the
              rights, property, and safety of Buildpad, our users, or others
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            4. Data Security
          </h2>
          <p className="text-charcoal-500">
            We take reasonable measures to help protect personal information
            from loss, theft, misuse, and unauthorized access, disclosure,
            alteration, and destruction.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            5. Data Retention
          </h2>
          <p className="text-charcoal-500">
            We store the information we collect about you for as long as is
            necessary for the purpose(s) for which we originally collected it.
            We may retain certain information for legitimate business purposes
            or as required by law.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            6. Your Rights
          </h2>
          <p className="text-charcoal-500">You have the right to:</p>
          <ul className="list-disc pl-5 mt-2 text-charcoal-500">
            <li>Access the personal information we hold about you</li>
            <li>
              Request that your personal information be corrected or deleted
            </li>
            <li>
              Object to or restrict our processing of your personal information
            </li>
            <li>
              Request a copy of your personal information in a structured,
              commonly used, and machine-readable format
            </li>
          </ul>
          <p className="mt-2 text-charcoal-500">
            To exercise these rights, please contact us at hello@buildpad.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            7. Changes to This Policy
          </h2>
          <p className="text-charcoal-500">
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on this page
            and updating the "Last Updated" date at the top of this policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            8. Contact Us
          </h2>
          <p className="text-charcoal-500">
            If you have any questions about this privacy policy, please contact
            us at hello@buildpad.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            9. Data Protection Officer
          </h2>
          <p className="text-charcoal-500">
            Our Data Protection Officer can be contacted at hello@buildpad.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-charcoal-500 mb-4">
            10. Supervisory Authority
          </h2>
          <p className="text-charcoal-500">
            If you are located in the European Union and believe we are
            unlawfully processing your personal information, you have the right
            to lodge a complaint with your local data protection supervisory
            authority. In Hungary, this is the Hungarian National Authority for
            Data Protection and Freedom of Information (NAIH).
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
