import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const Banner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const bannerClosed = localStorage.getItem('officialLaunchBannerClosed');
    if (!bannerClosed) {
      setIsVisible(true);
    }
  }, []);

  const closeBanner = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      localStorage.setItem('officialLaunchBannerClosed', 'true');
    }, 300); // Match this with the CSS transition duration
  };

  if (!isVisible) return null;

  return (
    <div
      className={`beta-banner py-2 px-4 flex items-center justify-between bg-terracotta-500 text-sm text-ivory-50 transition-all duration-300 ease-in-out ${
        isClosing
          ? 'max-h-0 py-0 opacity-0 overflow-hidden'
          : 'max-h-24 opacity-100'
      }`}
    >
      <div className="flex-1 text-center">
        <span className="font-medium">Buildpad has officially launched 🎉</span>{' '}
        <span className="px-1">•</span> As a thank you to our Beta users, we're
        offering you a 20% discount on Pro — just send an email to{' '}
        <a
          href="mailto:hello@buildpad.io"
          rel="noopener noreferrer"
          target="_blank"
          className="underline"
        >
          hello@buildpad.io
        </a>{' '}
        to get your promo code
      </div>
      <button
        onClick={closeBanner}
        className="text-ivory-50 hover:text-ivory-200 transition-colors duration-200"
        aria-label="Close banner"
      >
        <IoCloseOutline size={18} />
      </button>
    </div>
  );
};

export default Banner;
